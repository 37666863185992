<template>
  <footer class="footerarea">
    <div class="footer_body" v-if="device === 'desktop' && showMainFooter">
      <div class="container">
        <div class="row">
          <div class="col-6 col-sm-12" :class="`col-md-${pageCount1 < 6 ?  Math.round(12/pageCount1) : 2}`" v-for="(cat, inx) in pageList1" :key="inx">
            <footer-category-one-item :dataByCategory="cat"/>
          </div>
          <div class="px-0" v-if="pageList.length < maxCountPerRow">
            <div class="col-sm-12">
              <div class="footer">
                <div class="footer_logo d-flex"><img :src="srcLogo" alt="logo" title="" class="m-auto"/></div>
                <div class="d-flex">
                  <ul class="footer_social d-flex m-auto">
                    <li v-if="showTwitterLink"><a :href="twitterLink" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-x-twitter"></i></a></li>
                    <li v-if="showFacebookLink"><a :href="facebookLink" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-facebook-f"></i></a></li>
                    <li><a href="https://g.co/kgs/ApYvyz" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-google"></i></a></li>
                    <li v-if="showInstagramLink"><a :href="instagramLink" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-instagram"></i></a></li>
                    <!-- <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li> -->
                    <!-- <li><a href="#" target="_blank"><i class="fab fa-youtube"></i></a></li> -->
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer_body" v-if="pageCount2 && device === 'desktop' && showMainFooter">
      <div class="container">
        <div class="row">
          <div class="col-6 col-sm-12" :class="`col-md-${12/pageCount1}`" v-for="(cat, inx) in pageList2" :key="(pageCount1 + inx)">
            <footer-category-one-item :dataByCategory="cat"/>
          </div>
          <div class="px-0" >
            <div class="col-sm-12">
              <div class="footer">
                <div class="footer_logo d-flex"><img :src="srcLogo" alt="logo" title="" class="m-auto"/></div>
                <div class="d-flex">
                  <ul class="footer_social d-flex m-auto">
                    <li v-if="showTwitterLink"><a :href="twitterLink" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-x-twitter"></i></a></li>
                    <li v-if="showFacebookLink"><a :href="facebookLink" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-facebook-f"></i></a></li>
                    <li><a href="https://g.co/kgs/ApYvyz" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-google"></i></a></li>
                    <li v-if="showInstagramLink"><a :href="instagramLink" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-instagram"></i></a></li>
                    <!-- <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li> -->
                    <!-- <li><a href="#" target="_blank"><i class="fab fa-youtube"></i></a></li> -->
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer_body footer_top" v-if="device === 'desktop' && showCustomSubjectFooter">
      <div class="container">
        <div class="row d-flex justify-content-start" >
          <custom-footer-column v-for="(subject, i) in customFooterSubjects" :key="i"
            :subject="subject"
            :show="showCustomSubjectFooter"
          />
          <div class="col-2 col-md-2" v-for="(cat, inx) in pageList1" :key="(customFooterSubjects.length + inx)">
            <footer-category-one-item :dataByCategory="cat"/>
          </div>
          <div class="px-0" >
            <div class="col-sm-12">
              <div class="footer">
                <div class="footer_logo d-flex"><img :src="srcLogo" alt="logo" title="" class="m-auto"/></div>
                <div class="d-flex">
                  <ul class="footer_social d-flex m-auto">
                    <li v-if="showTwitterLink"><a :href="twitterLink" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-x-twitter"></i></a></li>
                    <li v-if="showFacebookLink"><a :href="facebookLink" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-facebook-f"></i></a></li>
                    <li><a href="https://g.co/kgs/ApYvyz" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-google"></i></a></li>
                    <li v-if="showInstagramLink"><a :href="instagramLink" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-instagram"></i></a></li>
                    <!-- <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li> -->
                    <!-- <li><a href="#" target="_blank"><i class="fab fa-youtube"></i></a></li> -->
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer_body" v-if="device === 'mobile'">
      <div class="container" v-if="showMainFooter">
        <footer-column-mobile-theme1
          v-for="(item, idx) in pageListFull" :key="idx"
          :itemList="item[2]"
          path=""
          :caption="item[1]"
          :index="item[0]"
        />
      </div>
      <div class="container" v-else-if="showCustomSubjectFooter">
        <custom-footer-column-mobile v-for="(subject, i) in customFooterSubjects" :key="i"
            :subject="subject"
            :index="i"
            :show="showCustomSubjectFooter"
        />
        <div class="text-center" v-for="(cat, inx) in pageList1" :key="inx">
          <footer-category-one-item :dataByCategory="cat"/>
        </div>
      </div>
      <div class="px-0" :class="`col-md-${pageCount1 < 7 ?  Math.round(12/pageCount1) : 2}`">
        <div class="col-sm-12">
          <div class="footer">
            <div class="footer_logo d-flex m-auto"><img :src="srcLogo" alt="logo" title="" class="m-auto"/></div>
            <div class="d-flex">
              <ul class="footer_social d-flex m-auto">
                <li v-if="showTwitterLink"><a :href="twitterLink" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-x-twitter"></i></a></li>
                <li v-if="showFacebookLink"><a :href="facebookLink" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-facebook-f"></i></a></li>
                <li><a href="https://g.co/kgs/ApYvyz" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-google"></i></a></li>
                <li v-if="showInstagramLink"><a :href="instagramLink" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-instagram"></i></a></li>
                <!-- <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li> -->
                <!-- <li><a href="#" target="_blank"><i class="fab fa-youtube"></i></a></li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="footer_middle" >
      <div class="container">
          <div class="">
            <div class="footer row">
              <div class="col-12 col-sm-12 col-md-12 text-center show-message form-group">{{$t("footer.member-login-alert")}}</div>
              <div class="col-6 col-md-2 col-sm-6 text-center m-auto">
                <member-register theme="theme1"/>
              </div>
            </div>
          </div>
      </div>
    </div> -->
    <div class="footer_bottom">
      <div class="container">
        <div class="copyright_area"><p>{{ $t("footer.all-reserved-title", {year: new Date().getFullYear(), agencyName: whiteLabel.name}) }}</p></div>
      </div>
    </div>

  </footer>
</template>

<script>
import TemplateFooter from './TemplateFooter';

export default {
  name: 'Footer',
  mixins: [TemplateFooter],
  components: {
    // MemberRegister: () => import('@/components/atoms/MemberRegister'),
    FooterCategoryOneItem: () => import('@/components/HeaderFooter/FooterCategoryOneItem'),
    // FooterCollapse: () => import('@/components/HeaderFooter/FooterCollapse'),
    CustomFooterColumn: () => import('@/components/HeaderFooter/customFooterColumn/CustomFooterColumnTheme0'),
    FooterColumnMobileTheme1: () => import('../footerColumn/FooterColumnMobileTheme1'),
    CustomFooterColumnMobile: () => import('../customFooterColumn/CustomFooterColumnMobileTheme1'),
  },
  data() {
    return {
      maxCountPerRow: 6,
    };
  },
  computed: {
    pageList1() {
      const { pageList } = this;
      if (!pageList) return [];
      return pageList.slice(0, this.maxCountPerRow - 1);
    },
    pageList2() {
      const { pageList } = this;
      if (!pageList) return [];
      return pageList.slice(this.maxCountPerRow - 1, pageList.length);
    },
    footerList() {
      return this.pageListFull;
    },
  },
  methods: {
  },
};
</script>

<style>
  body.flat-404 footer{
    margin-bottom: 0;
    color: white;
  }
  body.flat-404 .footer-btm .copy ul li a {
    color: white;
  }
  .row_title {
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 20px;
  }
</style>

<style scoped>
  .show-message{
    font-size: 1.5rem;
    color: white;
  }
  .link-nDetail .short-links .list-group-item{
    padding: 0;
  }
  footer .list-group,
  .link-nDetail .footer-social ul {
    padding: 0;
  }
  footer .shortLink-wrapper:not(:last-child){
    border-left: 1px solid white;
  }
  footer .shortLink-wrapper{
    padding: 0 1.5rem;
  }
  .new_footer_area{
    background-image: url('/assets/img/newfooterbg.jpg');
  }
  .footer_body .footer .footer_logo img{
    min-width: 200px;
  }
  .footer_body .footer_social li {
    padding: 15px;
  }
  .footerarea .footer_middle a.btn {
    border-radius: 3px;
  }
  .footerarea{
    background-position:center;
    background-size:cover;
    background-repeat:no-repeat;
    background-image: url("/assets/img/theme1/footerbg.jpg");
}
.footerarea .footer_top{ padding:78px 0; }

.footerarea .footer_top .footer_logo {
    max-width: 250px;
    margin: auto;
    margin-bottom: 30px;
}
.footerarea .footer-social ul {
    margin-top: 0.7em;
    text-align: center;
    margin-bottom: 0.5em;
}
 .footer-social ul li a {
  color: #0061ab;
  background: #fff;
  width: 2em;
  height: 2em;
  font-size: 1.2em;
  line-height: 1;
  margin: 0.3em 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 13%);
}
.footerarea .footer-social ul li a:hover{
    background-color: #0061ab;
    color: #fff;
}
.footerarea .footer_wizgetbox h5 {
    font-size: 18px;
    font-weight: 600;
    color: #FFFFFF;
    margin-bottom: 18px;
}
.footerarea .footer_wizgetbox .short-links .list-group-item {
  padding: 0.35rem 0;
  background-color: unset;
  border: none;
}
.footerarea .footer_wizgetbox .short-links .list-group-item a {
  color: #fff;
  font-size: 15px;
  font-weight: 300;
  transition: all .3s cubic-bezier(.645,.045,.355,1);
  position: relative;
  display: inline-block;
}
.footerarea .footer_wizgetbox .short-links .list-group-item.morelink a{color: #7BD7FF; font-size: 13px;}
.footerarea .footer_wizgetbox .short-links .list-group-item a:after {
    transition: all .3s cubic-bezier(0.65, 0.05, 0.36, 1);
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 1px;
    background-color: rgb(255 255 255 / 45%);
}
.footerarea .footer_wizgetbox .short-links .list-group-item:hover a:after {
    left: auto;
    width: 100%;
    right: 0;
}
.footerarea .footer_wizgetbox .short-links .list-group-item:hover a{ margin-right:5px; }

.footerarea .footer_middle{ background-color:#28A0D4; padding:30px 0; text-align:center; }
.footerarea .footer_middle h6{ color:#fff; font-weight:400; font-size:22px; margin-bottom:15px;}
.footerarea .footer_bottom{ padding:24px 0; text-align:center; }
.footerarea .footer_bottom p{ font-size:16px; color:#fff; font-weight:300; margin-bottom:0; }

@media(max-width: 1399px) {
  .footerarea .footer_wizgetbox .short-links .list-group-item a {
      font-size: 14px;
  }
  .footerarea .footer_middle h6 {
      font-size: 20px;
  }
  .footerarea .footer_top {
      padding: 60px 0;
  }
}

@media (max-width: 1199px){
  .footerarea .footer_top .order-2 {
      order: 1 !important;
  }
  .footerarea .footer_top .order-1 {
      order: 2 !important;
  }
}

@media (max-width:767.98px){
  .footerarea .footer_top {
      padding: 30px 0 0;
  }
  .footerarea .footer_wizgetbox h5 {
      font-size: 18px;
      margin-bottom: 8px;
  }
  .footerarea .footer_wizgetbox {
      margin-bottom: 30px;
  }
  .footerarea .footer_wizgetbox .short-links .list-group-item {
      padding-bottom: 0;
  }
  .footerarea .footer_middle h6 {
      font-size: 16px;
      margin-bottom: 6px;
  }
  .footerarea .footer_middle {
      padding: 15px 0;
  }
  .footerarea .btn-theme-onWhite {
      font-size: 14px;
      padding: 6px 16px;
  }
  .footerarea .footer_top .footer_logo {
      max-width: 240px;
      margin-bottom: 15px;
  }
  .footerarea .footer_bottom {
      padding: 10px 0;
  }
  .footerarea .footer_bottom p {
      font-size: 14px;
  }
}

@media (max-width:639.98px){
  .footerarea .footer_wizgetbox {
      margin-bottom: 24px;
      text-align: center;
  }
}

@media (max-width:410px){
  .footerarea .footer_top .footer_logo {
      max-width: 210px;
      margin-bottom: 15px;
  }
  .footerarea .footer-social ul li a {
      font-size: 16px;
  }
}
</style>
